import { Exclude, Expose, Type } from 'class-transformer';

import Firm from './Firm';

@Exclude()
class Group {
  @Expose()
  id!: number;

  @Expose()
  label!: string;

  @Expose()
  @Type(() => Firm)
  firms!: Array<Firm>;
}

export default Group;
