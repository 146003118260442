































































































































































































































































import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import TemplateDate from '@/templates/TemplateDate.vue';

import Pictogram from '@modules/chemical-risk/entities/Pictogram';
import ProductFolder from '@modules/chemical-risk/entities/ProductFolder';
import HazardStatement from '@modules/chemical-risk/entities/HazardStatement';

import { ProductSheetStatusEnumName } from '@modules/chemical-risk/types/enum';
import { AnnexVISubstance } from '@modules/chemical-risk/types/types';
import AnnexVI from '@modules/chemical-risk/entities/AnnexVI';

const cheModule = namespace('cheRisk');

@Component({
  components: {
    TemplateDate,
  },
})
export default class SynthesisTable extends Vue {
  @cheModule.Getter('getPictogram')
  getPictogram!: (id: number) => Pictogram;

  @cheModule.Getter('getHazardStatement')
  getHazardStatement!: (id: number) => HazardStatement;

  @cheModule.Getter('getCmrLabel')
  getCMRLabel!: (ids: Array<number>) => string;

  @cheModule.Getter('getSensibilityLabel')
  getSensibilityLabel!: (ids: Array<number>) => string;

  @cheModule.Action('loadAnnexVI')
  loadAnnexVI!: (uuid: string) => AnnexVISubstance;

  @Prop({ type: Array })
  elements!: Array<ProductFolder>;

  @Prop({ type: Array })
  annexVISubstances!: Array<any>;

  @Prop({ type: Object })
  readonly annexVIInformation!: { date: string, version: string };

  productSheetStatusEnum = ProductSheetStatusEnumName;

  getSubstances(productSheetUuid: string): AnnexVI[] {
    const aVI = this.annexVISubstances.find(aVIs => aVIs.uuid === productSheetUuid);

    return aVI ? aVI.substances : [];
  }

  getSensibilitySubstances(productSheetUuid: string): AnnexVI[] {
    const substances = this.getSubstances(productSheetUuid);

    return substances.filter(s => s.sensibilityLabel !== 'Non Sensibilisante' && s.sensibilityLabel !== '' && s.sensibilityLabel);
  }

  getCmrSubstances(productSheetUuid: string): AnnexVI[] {
    const substances = this.getSubstances(productSheetUuid);

    return substances.filter(s => s.cmrLabel !== 'Non CMR' && s.cmrLabel !== '' && s.cmrLabel);
  }

  isOld(year: string) {
    return moment(year, 'DD/MM/YYYY').isBefore(moment().subtract(5, 'year'));
  }

  CMRColor(CMR: any) {
    if (this.getCMRLabel(CMR).includes('(a)')) {
      return 'red';
    }
    if (this.getCMRLabel(CMR).includes('(s)')) {
      return 'yellow';
    }
    return 'green';
  }

  cellClass(payload: { row: any, column: any, rowIndex: number, columnIndex: number }) {
    const { row, column } = payload;
    if (this.getCMRLabel(row.productSheet.hazardStatements).includes('(a)') && column.label === 'CMR') {
      return 'cmr-red';
    }
    if (this.getCMRLabel(row.productSheet.hazardStatements).includes('(s)') && column.label === 'CMR') {
      return 'cmr-yellow';
    }
    return '';
  }

  headerClass(payload: { row: any, column: any, rowIndex: number, columnIndex: number }) {
    const { row, column } = payload;
    return '';
  }

  toggleAllComment() {
    (document.querySelector('.comment-label') as HTMLDivElement).classList.toggle('expanded');
    document.querySelectorAll<HTMLElement>('.el-table__expand-icon').forEach((e) => {
      e.click();
    });
  }

  momentify(date: string) {
    return moment(date).format('L');
  }

  updated(): void {
    this.$nextTick(() => {
      const trs: any = [].slice.call(document.querySelectorAll('.el-table__row'));
      this.elements.forEach((row, index) => {
        if (!row.comment && row.productSheet.comments.length === 0) {
          if (trs[index].querySelector('.el-table__expand-column .cell')) {
            trs[index].querySelector('.el-table__expand-column .cell').remove();
            if (trs[index].nextSibling) {
              trs[index].nextSibling.remove();
            }
          }
        }
      });
    });
  }
}
