import moment from 'moment';
import {
  Exclude,
  Expose,
  Transform,
  Type,
} from 'class-transformer';

import Firm from '@/entities/firm/Firm';
import Group from '@/entities/firm/Group';
import { FolderEnum } from '@/types/Folder';

import ProductFolder from './ProductFolder';

@Exclude()
class CheFolder {
  static entity = 'cheFolders';
  static primaryKey = 'id';

  @Expose({ notEmptyOnly: true })
  id: number | null = null;

  @Expose()
  reference: string | null = null;

  @Expose()
  title: string | null = null;

  @Expose()
  @Type(() => Date)
  @Transform(value => moment(value).format('YYYY-MM-DDTHH:mm:ss'), { toPlainOnly: true })
  beginAt!: Date;

  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  endAt!: Date | null;

  @Expose()
  status!: number;

  @Expose({ name: 'firm', toPlainOnly: true })
  firmId!: number;

  @Expose({ name: 'group', toPlainOnly: true })
  groupId!: number;

  @Type(() => Firm)
  firm!: Firm;

  @Type(() => Group)
  group!: Group;

  @Expose()
  @Type(() => ProductFolder)
  products!: Array<ProductFolder>;

  folderStats!: any;

  @Expose({ toClassOnly: true })
  files!: Array<any>;

  @Expose({ toClassOnly: true })
  nbProducts!: number;

  get isSave(): boolean {
    return this.id !== null;
  }

  get isActive(): boolean {
    return this.endAt === null || moment(this.endAt).isAfter(moment());
  }

  get isInClosing() {
    return this.status === FolderEnum.FOLDER_IN_CLOSING;
  }

  get isInError() {
    return this.status === -1;
  }
}

export default CheFolder;
