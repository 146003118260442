











import _ from 'lodash';
import { namespace } from 'vuex-class';
import { Vue, Component } from 'vue-property-decorator';

import { PrintActionInterface } from '@/types/breadcrumb';
import Layout from '@/layouts/LayoutDefault.vue';

import CheFolder from '@modules/chemical-risk/entities/CheFolder';
import HazardStatement from '@modules/chemical-risk/entities/HazardStatement';
import ProductFolder from '@modules/chemical-risk/entities/ProductFolder';

import SynthesisTable from '@modules/chemical-risk/components/SynthesisTable.vue';
import { AnnexVIInformation, AnnexVISubstance } from '@modules/chemical-risk/types/types';

const cheModule = namespace('cheRisk');

@Component({
  components: {
    SynthesisTable,
    Layout,
  },
})
export default class PreviewView extends Vue {
  @cheModule.Getter('getActiveCheFolder')
  activeCheFolder!: CheFolder;

  @cheModule.Getter('getHazardStatementByReference')
  getHazardStatementByReference!: (reference: string) => HazardStatement;

  @cheModule.Getter('getCmrLabel')
  getCMRLabel!: (ids: Array<number>) => string;

  @cheModule.Getter('getSensibilityLabel')
  getSensibilityLabel!: (ids: Array<number>) => string;

  @cheModule.Action('loadProductsFolder')
  loadProductsFolder: any;

  @cheModule.Action('getPreviewPDF')
  getPreviewPDF!: (id: any) => any;

  @cheModule.Action('loadAnnexVI')
  loadAnnexVI!: () => Promise<AnnexVISubstance[]>;

  @cheModule.Action('getAnnexVIInformation')
  getAnnexVIInformation!: () => Promise<AnnexVIInformation>;

  products: ProductFolder[] = [];
  loading: boolean = false;
  page: number = 1;
  total: number = 1;
  annexVISubstances: AnnexVISubstance[] = [];
  annexVIInformation: AnnexVIInformation = {
    date: '',
    version: '',
  };
  filters: any = {
    product: '',
    post: '',
    unit: '',
    cmr: '',
    sensibility: '',
    old: false,
    orderColumn: '',
    order: '',
  };

  get elements(): Array<ProductFolder> {
    return _.orderBy(this.products, ['position'], ['asc']);
  }

  public mounted() {
    this.load(this.page);
  }

  async load(page: number = 1) {
    const data = await this.loadProductsFolder({ page, filters: this.filters, limit: 0 });

    this.products = data.items;
    this.page = data.meta.page;
    this.total = data.meta.total;

    this.annexVISubstances = await this.loadAnnexVI();
    this.annexVIInformation = await this.getAnnexVIInformation();
    this.annexVISubstances.forEach((aVIS: any) => {
      aVIS.substances.forEach((s: any) => {
        const hazardsList: number[] = [];
        s.classificationHazardStatementCodes.split('\n').forEach((h: any) => {
          const hazardStatement = this.getHazardStatementByReference(h);
          if (hazardStatement) {
            hazardsList.push(hazardStatement.id);
          }
        });

        Vue.set(s, 'cmrLabel', this.getCMRLabel(hazardsList));
        Vue.set(s, 'sensibilityLabel', this.getSensibilityLabel(hazardsList));
      });
    });
  }

  get printAction(): PrintActionInterface {
    return {
      handler: () => {
        this.downloadPDF();
      },
    };
  }

  downloadPDF() {
    this.getPreviewPDF(this.activeCheFolder.id);
  }
}
